import { useParams } from "react-router-dom";
import { useStateMachine} from "little-state-machine";
import Header from "../components/header";
import {getLetters} from "../letters/data";

// firstName: data.firstName,
// lastName: data.lastName,
// email: data.email,
// mobileNumber: data.mobileNumber,
// companyName: data.companyName,
// jobName: data.jobName,
// skills: data.skills,
// lastJob: data.lastJob,
// lastCompany: data.lastCompany,
// accomplishments: data.accomplishments,

function TodayDate() {
  var myCurrentDate = new Date();
  var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth()+1) + '-' + myCurrentDate.getDate();
  const newCurrentDate = date;
  return (
    <p>{newCurrentDate}</p>
  );
}

export default function Results() {
  const {state} = useStateMachine();
  let letters = getLetters();
  let letter = letters[Math.floor(Math.random() * letters.length)];
  return (
    <div className="container is-fluid">
      <Header/>
      <div className="column is-three-fifths is-offset-one-fifth">
      <section className="cover-letter">
        <p className="has-text-right">{state.data.firstName} {state.data.lastName}</p>
        <p className="has-text-right">{state.data.mobileNumber}</p>
        <p className="has-text-right">{state.data.email}</p>
        <p className="has-text-right"><TodayDate/></p>
        <p>{letter.first} {state.data.companyName},</p>
        <p>{letter.second} {state.data.jobName} position at your company {letter.third} {state.data.companyName}. {letter.fourth} {state.data.skills}.
        </p>
        <p>{letter.fifth} {state.data.lastCompany}, {letter.sixth} {state.data.lastJob}; {letter.seventh} {state.data.accomplishments}.</p>
        <p>{letter.eighth} {state.data.companyName}</p>
        <p>{letter.ninth}</p>
        <p>{state.data.firstName} {state.data.lastName}</p>
      </section>
      </div>
    </div>
  );
}