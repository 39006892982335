import logo from "./logo.svg";
import "./App.scss";
import { useForm } from "react-hook-form";
import {Link, useParams, useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import Header from "./components/header";

function updateNameAction(globalState, payload) {
  return {
    ...globalState,
    data: {
      ...payload,
    },
  };
}

// function Name() {
//   const { state } = useStateMachine();

//   return <h1>{state.data.firstName}</h1>;
// }

export default function App() {
  const { state } = useStateMachine();

  let navigate = useNavigate();
  const { actions } = useStateMachine({
    updateNameAction,
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    actions.updateNameAction({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobileNumber: data.mobileNumber,
      companyName: data.companyName,
      jobName: data.jobName,
      skills: data.skills,
      lastJob: data.lastJob,
      lastCompany: data.lastCompany,
      accomplishments: data.accomplishments,
    });
    navigate("/results");
    console.log(state);
  };

  // console.log(watch("example")); // watch input value by passing the name of it

  return (
    <div className="container is-fluid">
      <Header/>
        <section className="section">
        <div class="message">
          <div class="message-body">
            Just Write your info and click generate cover letter!
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <div className="field">
            <label className="label">First name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="First name"
                {...register("firstName", { required: true, maxLength: 80 })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">Last name</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="Last name"
                {...register("lastName", { required: true, maxLength: 100 })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">Mobile number</label>
            <div className="control">
              <input
              className="input"
                type="tel"
                placeholder="Mobile number"
                {...register("mobileNumber", {
                  required: true,
                  minLength: 6,
                  maxLength: 12,
                })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">Company Name</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="Company Name"
                {...register("companyName", { required: true })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">Job that you applied for</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="Job that you applied for"
                {...register("jobName", { required: true })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">what services can you provide for this job?</label>
            <div className="control">
              <textarea
              className="textarea"
                {...register("skills", { required: true })}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">your last job (or your profession)</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="your last job (or your profession)"
                {...register("lastJob", {})}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">your last company name</label>
            <div className="control">
              <input
              className="input"
                type="text"
                placeholder="your last company name"
                {...register("lastCompany", {})}
              />
              </div>
            </div>
            <div className="field">
            <label className="label">What did you accomplish in your last job (or profession)?</label>
            <div className="control">
              <textarea
              className="textarea"
                {...register(
                  "accomplishments",
                  {}
                )}
              />
              </div>
            </div>
            <div className="field">
            <div className="control">
              <input className="button is-link" type="submit" value="Generate Cover Letter" />
              </div>
            </div>
          </fieldset>
        </form>
        </section>
    </div>
  );
}
