// firstName: data.firstName,
// lastName: data.lastName,
// email: data.email,
// mobileNumber: data.mobileNumber,
// companyName: data.companyName,
// jobName: data.jobName,
// skills: data.skills,
// lastJob: data.lastJob,
// accomplishments: data.accomplishments,

let letters = [
  {
    first: "Dear Hiring Manager at ",
    second: "I'm excited to be applying for the ",
    third: "and I am delighted by the opportunity to apply my knowledge at ",
    fourth: "I can provide ",
    fifth: "During my previous role at ",
    sixth: "as a ",
    seventh: "I provided a lot of services like ",
    eighth: "Thank you for your time and consideration. I'm looking forward to learning more about this position at ",
    ninth: "Sincerely,"
  },
  {
    first: "To Whom It May Concern at ",
    second: "I am interested in applying for the ",
    third: "and I am excited to reach new heights with ",
    fourth: "I am thrilled to apply my knowledge by providing ",
    fifth: "I was previously employed at ",
    sixth: "as a ",
    seventh: "I was consistently commended to give all I can like ",
    eighth: "Please review my resume for a deeper look at my work history and accomplishments. I look forward to work with you at ",
    ninth: "yours truly,"
  },
  {
    first: "Dear HR at ",
    second: "I was so pleased to see your posting for the ",
    third: "at ",
    fourth: "I would love to create a positive experiences in your company by providing ",
    fifth: "my previous experience at ",
    sixth: "as a ",
    seventh: "included me doing multiple tasks like ",
    eighth: "Thank you for considering me for this job . I will be honored to work with you at ",
    ninth: "Regards,"
  },
  {
    first: "Dear Hiring Manager at ",
    second: "I would like to express my deep interest in working as a ",
    third: "at ",
    fourth: "I believe I am a strong candidate for this position as I can invest in providing ",
    fifth: "I worked with ",
    sixth: "as a ",
    seventh: "and helped them develop their business by implementing multiple services like ",
    eighth: "I look forward to hearing from you soon and work with you at ",
    ninth: "All my best,"
  },
];

export function getLetters() {
  return letters;
}