import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import App from "./App";
import Results from "./routes/results";
import { StateMachineProvider, createStore, useStateMachine} from "little-state-machine";
const rootElement = document.getElementById("root");


createStore({
  data: {
    firstName: '',
    lastName: '',
    accomplishments: '',
    lastJob: '',
    lastCompany: '',
    skills: '',
    jobName: '',
    companyName: '',
    mobileNumber: '',
    email: ''
  }
});

// function Name() {
//   const {state} = useStateMachine();
//   console.log(state);
//   return <p>{state.data.firstName}</p>;
// }

render(
  <BrowserRouter>
  <StateMachineProvider>
  {/* <Name/> */}
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="results" element={<Results />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
    </StateMachineProvider>
  </BrowserRouter>,
  rootElement
);