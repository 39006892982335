import React, { Component } from 'react';
import {Link, useParams, useNavigate } from "react-router-dom";

class Header extends Component {
  render() {
    return (
    <header>
      <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            Cover Letter Generator
            {/* <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28"/> */}
          </Link>

          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              Home
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a
                  target="_blank"
                  href="https://ahmadyousef.me/"
                  className="button is-primary"
                >
                  <strong>My Website</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
  }
}

export default Header;